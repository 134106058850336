import styled from "styled-components"

let S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  background-color: #fff;
  border: 1px solid var(--border);

  & > div > p.hdg {
    font-weight: bold;
    width: 100%;
    font-size: 1.1rem;
    padding: 15px;
    min-height: 85px;
    border-bottom: 1px solid var(--border);
    background-color: var(--bg);
    color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div > .details {
    border-bottom: 1px solid var(--border);

    & > .pdf {
      border-bottom: 1px solid var(--border);
      padding: 15px 15px 15px 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;

      & > div {
        padding-top: 3px;
        padding-left: 15px;
      }

      & > div > a {
        font-weight: 600;
        display: block;
        font-size: 1rem;
      }

      & > div > small {
        display: block;
        font-size: 0.85rem;
        line-height: 18px;
      }
    }

    & > p {
      margin: 0;
      font-size: 0.9rem;
      padding: 7.5px 15px 7.5px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > span .success {
        color: var(--success);
      }

      & > span .notice {
        color: var(--notice);
      }

      & > span .error {
        color: var(--error);
      }
    }
  }

  & > div > table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 15px;
    font-size: 0.9rem;

    & tr:last-child th,
    & tr:last-child td {
      padding-bottom: 7.5px;
    }

    tr.hidden {
      display: none;
    }

    & th,
    & td {
      border-bottom: 1px solid var(--border);
      padding: 7.5px 5px 5px 5px;
    }

    th.right {
      text-align: right;
    }

    th.top {
      vertical-align: top;
    }

    th:first-child {
      width: 30%;
    }

    th:nth-child(2) {
      width: 5%;
    }

    td {
      text-align: left;
    }

    & .certif > div {
      display: flex;
      align-items: center;
      padding-right: 15px;
    }
  }

  & > form {
    width: 100%;
    padding-top: 0;
    padding-bottom: 15px;

    & > table {
      border-collapse: collapse;
      width: 100%;
      font-size: 0.85rem;

      & th,
      & td {
        padding-top: 2.5px;
        padding-bottom: 2.5px;
      }

      & th:first-child,
      & td:first-child {
        padding-right: 15px;
        text-align: right;
      }

      & tbody td {
        border-bottom: 1px solid #eee;
      }

      & tbody tr:last-child td {
        border-bottom: none;
      }
    }

    & > div {
      margin-top: 25px;
      margin-bottom: 7.5px;
      padding: 0 15px;
    }
  }

  & > div.added {
    margin-top: 5px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 0.9rem;

    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--success);

      & > span {
        padding-top: 5px;
      }
    }

    & > div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;

      & > * {
        width: 125px;

        &:first-child {
          margin-right: 7.5px;
        }

        &:last-child {
          margin-left: 7.5px;
        }
      }
    }
  }

  & > .expired {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--error);
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 0.95rem;

    & > span {
      padding-top: 7.5px;
    }
  }
`

export default S
