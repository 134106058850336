import styled from "styled-components"

let S = {}
S.SectionContainer = styled.section`
  border-top: 1px solid var(--border);

  & > div {
    margin: 0 auto;
    margin-top: 25px;
    padding: 0 25px 35px 25px;
    display: flex;
    align-items: center;
    flex-direction: column;

    & > p {
      margin-top: 7.5px;
      margin-bottom: 7.5px;
    }

    & p.subhdg {
      font-size: 1rem;
    }

    & small {
      display: flex;
      align-items: center;
    }

    & small > span:first-child {
      font-size: 0.9rem;
      padding-bottom: 2px;
    }

    & small > span:last-child {
      padding-left: 4px;
    }
  }
`

export default S
