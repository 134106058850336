import React, { useState, useEffect } from "react"
import { storage } from "../../utils/firebase"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUserCircle,
  faFileAlt,
  faExternalLinkSquare,
} from "@fortawesome/pro-light-svg-icons"

const Welcome = () => {
  const [href, setHref] = useState("")

  useEffect(() => {
    const init = async () => {
      const ref = storage.ref("order-form.pdf")
      const url = await ref.getDownloadURL()
      setHref(url)
    }

    init()
  }, [])

  return (
    <S.Grid>
      <div>
        <h2>In need of CLER Credit? We can help!</h2>
        <p>
          The Palm Beach County Bar Association (PBCBA) offers CLE hours from
          previously recorded live and virtual seminars of the Association, in a
          variety of practice areas.
        </p>
        <p>
          Members and non-members can benefit from this simple way of acquiring
          CLE hours, to include many courses that offer Ethics, Professionalism
          and Technical credit.
        </p>
        <p>
          You have the option of purchasing CD’s, MP3’s, MP4’s and DVD’s for
          many of the courses. MP3’s and MP4’s can be immediately streamed or
          downloaded after purchase. Please note that CD and DVD purchase orders
          will be processed within three (3) business days before shipping.
        </p>
      </div>

      <div className="right">
        <div>
          <div className="icon-container">
            <FontAwesomeIcon icon={faUserCircle} size="2x" />
          </div>

          <div>
            <p className="hdg">PBCBA Members</p>

            <p style={{ marginBottom: "7.5px" }}>
              <a
                href="https://palmbeachbar.org/become-a-member/"
                target="_blank"
                rel="noreferrer"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ paddingRight: "7.5px", fontWeight: "600" }}>
                  Membership Application
                </span>
                <FontAwesomeIcon icon={faExternalLinkSquare} />
              </a>
            </p>

            <p>
              If you are a current PBCBA member in good standing, you are
              entitled to order our courses at a reduced rate. Please enter
              your <strong>Florida Bar Number</strong> during checkout to take
              advantage of member pricing.
            </p>

            <p>
              Not yet a member or need to renew your membership? Please visit
              our{" "}
              <a href="https://palmbeachbar.org/become-a-member/">
                Membership Application
              </a>{" "}
              page on our website for more details.
            </p>
          </div>
        </div>

        <div>
          <div className="icon-container">
            <FontAwesomeIcon icon={faFileAlt} size="2x" />
          </div>

          <div>
            <p className="hdg">Print & Mail Order Form</p>

            <p style={{ marginBottom: "7.5px" }}>
              <a
                href={href}
                target="_blank"
                rel="noreferrer"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ paddingRight: "7.5px", fontWeight: "600" }}>
                  Download Order Form
                </span>
                <FontAwesomeIcon icon={faExternalLinkSquare} />
              </a>
            </p>

            <p>
              If you prefer to pay by check, please{" "}
              <a href={href} target="_blank" rel="noreferrer">
                download
              </a>{" "}
              and print our CLE content order form and mail your order
              information along with payment to the Bar Association.{" "}
              <span className="red">
                Please include $10 for shipping and handling
              </span>
              .
            </p>
          </div>
        </div>
      </div>
    </S.Grid>
  )
}

export default Welcome
