import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons"

const Expired = () => {
  return (
    <div className="expired">
      <FontAwesomeIcon icon={faTimesCircle} size="lg" />
      <span>This course has expired.</span>
    </div>
  )
}

export default Expired
