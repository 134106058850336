import React from "react"

const Hdg = ({ name }) => {
  return (
    <p className="hdg">
      <span>{name}</span>
    </p>
  )
}

export default Hdg
