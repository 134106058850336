import styled from "styled-components"

let S = {}
S.Grid = styled.div`
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0 25px 0 25px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-gap: 0;

  @media only screen and (max-width: 649px) {
    grid-template-columns: 1fr;
  }

  & > div {
    border: 1px solid var(--border);
    border-top: none;
    padding: 0 0 0 15px;

    @media only screen and (max-width: 649px) {
      padding: 0;
    }
  }

  & > div.left {
    & > div {
      border-bottom: 1px solid var(--border);
      margin-bottom: 0;

      & > button {
        border: none;
        width: 100%;
        padding: 10px 0 9px 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.9rem;
        letter-spacing: 0.75px;
        transition: none;
        text-align: left;

        &:hover {
          background-color: var(--bg);
        }

        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &.selected > button {
        background-color: var(--bg);
      }

      & > ul {
        margin: 0;
        padding: 0;
        margin-top: 15px;
        margin-left: 25px;
        margin-bottom: 5px;
        font-size: 0.85rem;
        line-height: 18px;

        @media only screen and (max-width: 649px) {
          margin-left: 35px;
        }

        & > li {
          padding-bottom: 12.5px;
          padding-left: 0;
          padding-right: 15px;

          & > button {
            text-align: left;
          }
        }
      }
    }

    & > div:last-child {
      border-bottom: none;
    }
  }

  & > div.right {
    border-left: none;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media only screen and (max-width: 649px) {
      border-left: 1px solid var(--border);
    }

    & > div.none-selected {
      align-self: center;
      text-align: center;
      color: var(--working);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > span {
        padding-top: 5px;
      }
    }

    & .course-enter,
    & .course-appear {
      opacity: 0;
    }

    & .course-enter-active,
    & .course-appear-active {
      opacity: 1;
      transition: opacity 300ms;
    }

    & .course-exit {
      opacity: 1;
    }

    & .course-exit-active {
      opacity: 0;
      transition: opacity 300ms;
    }
  }
`

export default S
