import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/pro-light-svg-icons"

const Details = ({ pdf, sponsor, expires }) => {
  return (
    <div className="details">
      <div className="pdf">
        <FontAwesomeIcon
          icon={faFilePdf}
          size="3x"
          style={{ fontSize: "2.5rem" }}
        />

        <div>
          <a href={pdf.url} target="_blank" rel="noreferrer">
            {pdf.name}
          </a>
          <small>
            Course overview, speaker information, specific topics covered and
            other course details are included in this document.
          </small>
        </div>
      </div>

      <p>
        <span>Sponsor: {sponsor}</span>
        <span>
          Expires: <span className={expires.status}>{expires.date}</span>
        </span>
      </p>
    </div>
  )
}

export default Details
