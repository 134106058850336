import React from "react"
import S from "./styled"

const Toggle = ({ selected, select }) => {
  return (
    <S.Grid>
      <p>
        To view courses available for ordering, please select either the{" "}
        <strong>“By Area of Law”</strong> or{" "}
        <strong>“By Certification Credit”</strong> tab to sort courses by your
        desired criteria. Click the appropriate category button to reveal
        courses and the course title to show/hide additional details.
      </p>

      <button
        type="button"
        className={selected === "areaoflaw" ? "left selected" : "left"}
        onClick={() => select("areaoflaw")}
      >
        By Area of Law
      </button>
      <button
        type="button"
        className={selected === "certcredit" ? "right selected" : "right"}
        onClick={() => select("certcredit")}
      >
        By Certification Credit
      </button>
    </S.Grid>
  )
}

export default Toggle
