import React, { useState, useRef } from "react"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import Courses from "./Courses"
import Course from "./Course"
import S from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronDown,
  faExclamationCircle,
} from "@fortawesome/pro-light-svg-icons"

const Content = ({ categories, crit }) => {
  const [selected, setSelected] = useState("")
  const [course, setCourse] = useState({})

  const divRef = useRef(null)

  const handleClick = categoryId => {
    setCourse({})
    if (selected === categoryId) {
      setSelected("")
    } else {
      setSelected(categoryId)
    }
  }

  const handleCourse = obj => {
    if (obj.id) {
      setCourse(obj)
      divRef.current.scrollIntoView()
    } else {
      setCourse({})
    }
  }

  return (
    <S.Grid>
      <div className="left">
        {categories.map(item => {
          return (
            <div
              key={item.id}
              className={item.id === selected ? "selected" : ""}
            >
              <button type="button" onClick={() => handleClick(item.id)}>
                <div>
                  {item.name}
                  <FontAwesomeIcon
                    icon={item.id === selected ? faChevronDown : faChevronRight}
                    size="1x"
                  />
                </div>
              </button>
              {item.id === selected && (
                <Courses
                  categoryId={item.id}
                  click={handleCourse}
                  selected={course.id}
                  crit={crit}
                />
              )}
            </div>
          )
        })}
      </div>

      <div className="right" ref={divRef}>
        {course.id ? (
          <SwitchTransition mode="out-in">
            <CSSTransition
              appear={true}
              key={course.id}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false)
              }}
              classNames="course"
            >
              <Course item={course} click={handleCourse} />
            </CSSTransition>
          </SwitchTransition>
        ) : (
          <div className="none-selected">
            <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
            <span>No course selected</span>
          </div>
        )}
      </div>
    </S.Grid>
  )
}

export default Content
