import styled from "styled-components"

let S = {}
S.Grid = styled.div`
  margin: 0 auto;
  padding: 25px 25px 0 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  & p {
    padding: 0 25px;
    text-align: center;
    grid-column: 1 / span 2;
    font-size: 0.9rem;
    line-height: 22px;

    @media only screen and (max-width: 649px) {
      padding: 0;
    }
  }

  & > button {
    margin-top: 15px;
    width: 100%;
    padding: 15px 0;
    border: none;
    border-radius: 0;
    background-color: #fff;
    border: 1px solid var(--border);
    cursor: pointer;
    letter-spacing: 0.25px;
    outline: none;
    font-size: 1.1rem;
    back
  }

  & > button:hover {
    color: var(--blue);
    background-color: var(--bg);
  }

  & > button.left {
  }

  & > button.right {
    border-left: none;
  }

  & > button.selected {
    background-color: var(--bg);
    color: var(--blue);
    font-weight: bold;
  }
`
export default S
