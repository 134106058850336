import React from "react"
import { Link } from "gatsby"
import bg from "../../img/background+logo.jpg"
import S from "./styled"

const Hdr = ({ showCartLink = true }) => {
  return (
    <S.SectionContainer>
      <header>
        <div className="left">
          <div>
            <h1>
              <a href="https://palmbeachbar.org">
                Palm Beach County Bar Association
              </a>{" "}
              CLE Ordering
            </h1>
            <p>
              Purchase CD’s, DVD’s, MP3’s & MP4’s recorded at previous seminars
              for CLE hours.
            </p>
          </div>
        </div>

        <div className="right">
          {showCartLink ? (
            <Link to="/cart" className="button">
              View Cart
            </Link>
          ) : (
            <Link to="/" className="button">
              View Courses
            </Link>
          )}
        </div>
      </header>

      <a
        href="https://palmbeachbar.org"
        style={{
          backgroundImage: `url(${bg})`,
        }}
        title="PBCBA Home Page"
      ></a>
    </S.SectionContainer>
  )
}

export default Hdr
