import React from "react"
import Hdg from "../../../components/Course/Hdg"
import Details from "../../../components/Course/Details"
import Table from "../../../components/Course/Table"
import Form from "../../../components/Course/Form"
import Expired from "../../../components/Course/Expired"
import S from "../../../components/Course/styled"

const Course = ({ item, click }) => {
  return (
    <S.Container>
      <div>
        <Hdg name={item.name} />

        {item.pdf.name && (
          <Details
            pdf={item.pdf}
            sponsor={item.sponsor}
            expires={item.expires}
          />
        )}

        <Table
          data={{
            credit: item.credit,
            ethics: item.ethics,
            professionalism: item.professionalism,
            technology: item.technology,
            mental: item.mental,
            bias: item.bias,
            certif: item.certif,
          }}
        />
      </div>

      {item.expires.status === "error" ? (
        <Expired />
      ) : (
        <Form
          id={item.id}
          name={item.name}
          expires={item.expires.date}
          formats={item.formats}
          click={click}
        />
      )}
    </S.Container>
  )
}

export default Course
