import React, { useState, useEffect, Fragment } from "react"
import useFetch from "../../hooks/useFetch"
import { API_URL } from "../../utils"

const Courses = ({ categoryId, click, selected, crit }) => {
  const [url, setUrl] = useState(null)
  const [courses, setCourses] = useState([])

  const { loading, data } = useFetch(url)

  useEffect(() => {
    const newUrl = new URL(`${API_URL}/coursesInit`)
    const params = {
      categoryId,
      crit,
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data && data.courses.length > 0) {
      setCourses(data.courses)
    }
  }, [data])

  return (
    <Fragment>
      {loading ? (
        <div
          style={{
            padding: "15px",
            fontSize: "0.85rem",
            color: "var(--working)",
          }}
        >
          Loading...
        </div>
      ) : data.courses.length === 0 ? (
        <div
          style={{
            padding: "15px",
            fontSize: "0.85rem",
            color: "var(--working)",
          }}
        >
          No courses available at this time
        </div>
      ) : (
        <ul>
          {courses.map(item => {
            return (
              <li key={item.id}>
                <button
                  type="button"
                  onClick={() => click(item)}
                  className={item.id === selected ? "link selected" : "link"}
                >
                  {item.name}
                </button>
              </li>
            )
          })}
        </ul>
      )}
    </Fragment>
  )
}

export default Courses
