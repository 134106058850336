import React, { useState, useEffect, useRef, Fragment } from "react"
import { Link, navigate } from "gatsby"
import { getValFromLS, setValToLS, fmtCurrency } from "../../utils"
import CheckboxOrder from "../CheckboxOrder"
import Msg from "../Msg"
import Submit from "../Submit"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleRight,
  faCheckCircle,
} from "@fortawesome/pro-light-svg-icons"

function fmtDate(str) {
  const d = new Date(str)
  return `${d.getMonth() + 1}/${d.getDate()}/${d
    .getFullYear()
    .toString()
    .substr(-2)}`
}

function getAmt(array, format, memOrNon) {
  let amt = 0
  array.forEach(item => {
    if (item.value === format) {
      amt = item[memOrNon]
    }
  })
  return amt
}

const Form = ({ id, name, expires, formats, click }) => {
  const [added, setAdded] = useState(false)
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  useEffect(() => {
    if (added && !click) {
      navigate("/cart")
    }
  }, [added])

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    let array = getValFromLS("order", true)
      ? JSON.parse(getValFromLS("order", true))
      : []
    const startItems = array.length

    if (names.cd) {
      array.push({
        id,
        name,
        expires: fmtDate(expires),
        format: "cd",
        member: getAmt(formats, "cd", "member"),
        nonmember: getAmt(formats, "cd", "nonmember"),
      })
    }
    if (names.dvd) {
      array.push({
        id,
        name,
        expires: fmtDate(expires),
        format: "dvd",
        member: getAmt(formats, "dvd", "member"),
        nonmember: getAmt(formats, "dvd", "nonmember"),
      })
    }
    if (names.mp3) {
      array.push({
        id,
        name,
        expires: fmtDate(expires),
        format: "mp3",
        member: getAmt(formats, "mp3", "member"),
        nonmember: getAmt(formats, "mp3", "nonmember"),
      })
    }
    if (names.mp4) {
      array.push({
        id,
        name,
        expires: fmtDate(expires),
        format: "mp4",
        member: getAmt(formats, "mp4", "member"),
        nonmember: getAmt(formats, "mp4", "nonmember"),
      })
    }

    if (array.length > startItems) {
      setValToLS("order", JSON.stringify(array), true)
      setNames({})
      setAdded(true)
    } else {
      setMsg({
        type: "error",
        text: "No format selected.",
      })
    }
  }

  return (
    <Fragment>
      {added && click ? (
        <div className="added">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} size="lg" />
            <span>Item added to cart</span>
          </div>

          <div>
            <button
              type="button"
              className="button"
              onClick={() => click({ id: null })}
            >
              Continue
            </button>
            <Link to="/cart" className="button">
              View Order
            </Link>
          </div>
        </div>
      ) : added ? (
        <div />
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={e => handleSubmit(e)}
          ref={formElement}
        >
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Format</th>
                <th>PBCBA Member</th>
                <th>Non Member</th>
              </tr>
            </thead>
            <tbody>
              {formats.map(item => {
                return (
                  <tr key={item.value}>
                    <td className="center">
                      <CheckboxOrder
                        name={item.value}
                        reqd={false}
                        update={handleUpdate}
                        errors={errors}
                      />
                    </td>
                    <td className="center">{item.value.toUpperCase()}</td>
                    <td className="center">{fmtCurrency(item.member / 100)}</td>
                    <td className="center">
                      {fmtCurrency(item.nonmember / 100)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className="msg-submit">
            {msg.type && <Msg data={msg} />}
            {msg.type !== "working" && (
              <Submit
                name="Add Selected to Order"
                icon={faChevronCircleRight}
              />
            )}
          </div>
        </form>
      )}
    </Fragment>
  )
}

export default Form
