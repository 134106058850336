import React from "react"
import S from "./styled"

const Ftr = () => {
  return (
    <S.SectionContainer>
      <div>
        <p className="subhdg">
          For status updates on CD orders, please contact Nicole Nelson at{" "}
          <a href="mailto:nnelson@palmbeachbar.org">nnelson@palmbeachbar.org</a>
          .
        </p>

        <p className="subhdg" style={{ marginTop: "0" }}>
          For technical issues, please contact Derek Loiseau at{" "}
          <a href="mailto:dloiseau@palmbeachbar.org">
            dloiseau@palmbeachbar.org
          </a>
          .
        </p>

        <small>
          <span>©</span>
          <span>
            2021-23, Palm Beach County Bar Association. All Rights Reserved.
          </span>
        </small>
      </div>
    </S.SectionContainer>
  )
}

export default Ftr
