import React, { useState, useEffect, Fragment } from "react"
import useFetch from "../hooks/useFetch"
import { API_URL } from "../utils"
import Helmet from "../components/Helmet"
import Hdr from "../components/Hdr"
import Welcome from "../pagesHome/Welcome"
import Toggle from "../pagesHome/Toggle"
import Content from "../pagesHome/Content"
import Ftr from "../components/Ftr"

const HomePage = () => {
  const [url, setUrl] = useState(null)
  const [tab, setTab] = useState("areaoflaw")
  const [categories, setCategories] = useState([])

  const { data } = useFetch(url)

  useEffect(() => {
    const newUrl = new URL(`${API_URL}/homeInit`)
    const params = {
      crit: tab,
    }
    newUrl.search = new URLSearchParams(params)
    setUrl(newUrl)
  }, [tab])

  useEffect(() => {
    if (data && data.categories.length > 0) {
      setCategories(data.categories)
    }
  }, [data])

  const handleSelect = str => {
    setTab(str)
  }

  return (
    <Fragment>
      <Helmet
        title=""
        descr="The Palm Beach County Bar Association (PBCBA) offers CLE hours from the sale of audio MP3’s, MP4’s and CD’s recorded at previously held live seminars of area bar associations."
      />
      <Hdr />
      <Welcome />
      <Toggle selected={tab} select={handleSelect} />
      <Content categories={categories} crit={tab} />
      <Ftr />
    </Fragment>
  )
}

export default HomePage
