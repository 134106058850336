import styled from "styled-components"

let S = {}
S.SectionContainer = styled.section`
  padding: 0;
  border-bottom: 1px solid var(--border);
  box-shadow: var(--boxshadow);
  background-color: var(--bg);

  & > header {
    padding: 15px;
    display: grid;
    grid-template-columns: 10fr 1.5fr;
    align-items: center;

    @media only screen and (max-width: 649px) {
      grid-template-columns: 1fr;
    }

    & > div.left {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media only screen and (max-width: 649px) {
        flex-direction: column;
        margin-bottom: 5px;
      }

      /* removed logo
      & > a {
        width: 10%;

        @media only screen and (max-width: 649px) {
          width: 15%;
          padding-bottom: 15px;
        }
      }*/

      & > div {
        margin-left: 15px;

        @media only screen and (max-width: 649px) {
          margin-left: 0;
          text-align: center;
        }

        & > h1 {
          color: var(--blue);
          line-height: 28px;

          @media only screen and (max-width: 649px) {
            margin-bottom: 10px;
          }
        }

        & > p {
          @media only screen and (max-width: 649px) {
            margin-bottom: 10px;
          }
        }
      }
    }

    & > div.right {
      text-align: right;

      @media only screen and (max-width: 649px) {
        text-align: center;
      }

      @media print {
        display: none;
      }
    }

    & .button {
      @media only screen and (max-width: 649px) {
        margin-bottom: 5px;
      }
    }
  }

  & > a {
    text-decoration: none;
    color: inherit;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    height: 300px;
    background-origin: content-box;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media only screen and (max-width: 649px) {
      height: 250px;
    }

    @media only screen and (min-width: 1349px) {
      height: 350px;
    }

    @media print {
      display: none;
    }
  }
`

export default S
