import React from "react"
import { createMarkup } from "../../utils/"

const Table = ({ data }) => {
  return (
    <table>
      <tbody>
        <tr>
          <th className="right">Credit General</th>
          <th>:</th>
          <td>{data.credit}</td>
        </tr>
        <tr className={data.ethics ? "" : "hidden"}>
          <th className="right">Ethics</th>
          <th>:</th>
          <td>{data.ethics}</td>
        </tr>
        <tr className={data.professionalism ? "" : "hidden"}>
          <th className="right">Professionalism</th>
          <th>:</th>
          <td>{data.professionalism}</td>
        </tr>
        <tr className={data.technology ? "" : "hidden"}>
          <th className="right">Technology</th>
          <th>:</th>
          <td>{data.technology}</td>
        </tr>
        <tr className={data.mental ? "" : "hidden"}>
          <th className="right">Mental Illness</th>
          <th>:</th>
          <td>{data.mental}</td>
        </tr>
        <tr className={data.bias ? "" : "hidden"}>
          <th className="right">Bias Elimination</th>
          <th>:</th>
          <td>{data.bias}</td>
        </tr>

        <tr>
          <th className="right top">Certification Credit</th>
          <th className="top">:</th>
          <td>
            <div className="certif">
              {data.certif.map((item, index) => {
                return (
                  <div key={index}>
                    <span>{item.credit}</span>
                    <span
                      dangerouslySetInnerHTML={createMarkup(
                        ` &nbsp;&nbsp;=&nbsp; ${item.hours}`
                      )}
                    />
                  </div>
                )
              })}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default Table
